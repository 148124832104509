import React, { useEffect } from 'react';
import { NextComponentType } from 'next';
import Head from 'next/head';
import { defineMessages, useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core';
import { marketingParamsToStore, storeMarketingValue, trackPageView } from '../components/googleAnalytics';
import { getParameterByName } from '../components/utils';
import { availableLanguages } from '../appTypes';
import { logger } from '../utils/logger';

fontAwesomeConfig.autoAddCss = false;

declare global {
  interface Window {
    MusicKit: any;
    plausible: any;
  }
}

const { pageDescription, pageName } = defineMessages({
  pageDescription: {
    id: 'web.page.description',
    defaultMessage: 'TeslaEesti.'
  },
  pageName: {
    id: 'web.page.name',
    defaultMessage: 'TeslaEesti.com'
  }
});

const BaseComponent: React.FC<{ Component: NextComponentType; pageProps: any }> = ({ Component, pageProps }) => {
  const intl = useIntl();
  const router = useRouter();

  useEffect(() => {
    marketingParamsToStore.forEach((key) => {
      const value = getParameterByName(key, window.location.href);
      if (value) {
        storeMarketingValue(key, value);
      }
    });
  }, []);

  const title = intl.formatMessage(pageName);
  const description = intl.formatMessage(pageDescription);

  useEffect(() => {
    trackPageView();
    logger(`Page changed: ${router.asPath}`);
  }, [router.asPath]);
  /* eslint-disable react/no-danger */
  return (
    <>
      <Head>
        <meta itemProp="name" content={title} />
        <meta itemProp="description" content={description} key="itemProp-description" />
        <meta name="description" content={description} key="description" />
        {availableLanguages &&
          availableLanguages.map((l: string) => {
            let currentURL = router.asPath;
            if (router.defaultLocale !== l) {
              currentURL = `/${l}${router.asPath}`;
            }
            return <link rel="alternate" key={l} hrefLang={l} href={currentURL} />;
          })}
      </Head>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...pageProps} />
    </>
  );
  /* eslint-enable react/no-danger */
};

export const Base = React.memo(BaseComponent);
