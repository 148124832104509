export const makePageTitle = (title: string): string => (title ? `${title} - TeslaEesti.com` : 'TeslaEesti.com');

export function getParameterByName(name: string, url: string): string | null {
  const parsedName = name.replace(/[[\]]/g, '\\$&');

  const regex = new RegExp(`[?&]${parsedName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
